import React from "react";

export default function Custom404() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 text-center">
        <h1 className="text-5xl text-[#ff5733] mb-4">404</h1>
        <p className="text-xl mb-5">
          Oops! The page you're looking for is not here.
        </p>
        <a
          href="/"
          className="block w-full no-underline bg-red-500 text-white px-4 py-2 rounded-md font-semibold transition-colors duration-300 ease-in-out"
        >
          Go Back to Home
        </a>
      </div>
    </div>
  );
}
